/* for desktop */
      .whatsapp_float {
          position: fixed;
          width: 60px;
          height: 60px;
          bottom: 60px;
          right: 40px;
          background-color: #25d366;
          color: #FFF;
          border-radius: 50px;
          text-align: center;
          font-size: 30px;
          box-shadow: 2px 2px 3px #999;
          z-index: 100;
      }

      .whatsapp-icon {
          margin-top: 16px;
      }

      /* for mobile */
      @media screen and (max-width: 767px) {
          .whatsapp-icon {
              margin-top: 10px;
          }

          .whatsapp_float {
              width: 40px;
              height: 40px;
              bottom: 60px;
              right: 10px;
              font-size: 22px;
          }
      }

      
/* for newsletter and contact form */
.newsletter::placeholder  { 
    color: rgb(104, 103, 103);
    opacity: 1;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
}
button{
	height: 50px;
	padding: 0;
	margin: 0;
	
}


